import { Box, Button, Container, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactFooter from "../components/ContactFooter";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponent2colWhite from "../components/IntroComponent2colWhite";
import PortfolioGridWhite from "../components/PortfolioGridWhite";
import SixPointsBanner from "../components/SixPointsBanner";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
export default function Targets() {
	const { setColorMode } = useColorMode();

	useEffect(() => {
		setColorMode("light");
	});

	return (
		<>
			<Helmet>
				<title>tokentus |  Investment Targets</title>
				<meta
					name="description"
					content="We believe in you! We want to meet and invest in companies, that use the blockchain as an essential technology to build their business."
				/>
				<link rel="canonical" href="https:/tokentus.com/targets" />
			</Helmet>
			<Box bg="brand.black">
				<ImgTextModule
					src={"112358.mp4"}
					delay={0.3}
					duration={0.5}
					threshold={0.3}
					initialY={10}
					initialX={10}
					video={true}
					h="95vh"
					mediaOpacity={0.8}
					bgColor="brand.black">
					<Container variant="layoutContainer" mt={8}>
						<Box p={{ base: 0, lg: "8" }}>
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text
									color="white"
									fontWeight="normal"
									fontSize="xl">
									Investment targets
								</Text>
							</FadeInAnimation>
							<FadeInAnimation
								threshold={0.4}
								delay={0.4}
								duration={0.7}
								initialX={0}
								initialY={-10}>
								<Text
									color="brand.green"
									fontWeight="normal"
									fontSize={{ base: "5xl", lg: "6xl" }}>
									We believe in you.
								</Text>
							</FadeInAnimation>

							<FadeInAnimation
								threshold={0.4}
								delay={1}
								duration={0.7}
								initialX={0}
								initialY={10}>
								<Text
									color="brand.white"
									maxW={{ base: "100%", lg: "50%" }}
									fontSize="2xl"
									mb={24}>
									We are investors. We are mentors. We are
									believers.
								</Text>
							</FadeInAnimation>
						</Box>
					</Container>
				</ImgTextModule>

				<IntroComponent2colWhite
					version="thirds"
					darktypo={"dark"}
					headline="What we are looking for."
					text1="We want to meet companies, that use the blockchain as an essential technology to build their business."
					text2=" Where does the blockchain help to disrupt classic business models? This is the main question. We are as far as the industry is concerned open-minded, but technologically focused."
				/>

				<IntroComponent2colWhite
					version="thirds"
					darktypo={"dark"}
					headline="We are not alone."
					text1="We research and invest while being part of a strong network of co-investors."
					text2="A successful fund raising is determined by high quality investors. We co-invest with the best blockchain VCs around the world."
				/>
				<SixPointsBanner />
				<IntroComponent2colWhite
					headline="How to start ?"
					text1="If you feel that we are a good partner and investor. "
					text2="Don't hesitate to contact us directly. Preferably by phone. A direct call is the best way to get connected. If you fit, we are a match."
				/>

				<ContactFooter
					title="Are you ready ?"
					name="Benedikt Schulz, Investment Manager"
					email="benedikt.schulz@tokentus.com"
				/>

				<IntroComponent2colWhite
					version="thirds"
					headline="Our investments."
					text1="The decision to invest is always mutual."
					text2="The target has to choose tokentus as an investor. To be part of a great portfolio is definitely a good argument. To see and be seen."
				/>

				<PortfolioGridWhite />

				{/* CONTAINER QUADFEATURE */}
				<Container
					variant="layoutContainer"
					mt={0.5}
					mb={0.5}
					display="grid"
					gridGap={0.5}
					gridTemplateColumns={{
						lg: "repeat(2, 1fr)",
						md: "repeat(2, 1fr)",
						sm: "repeat(1, 1fr)",
					}}>
					<ImgTextModule
						minH="450px"
						w="100%"
						src={"lac4.mp4"}
						delay={0.9}
						duration={1}
						threshold={0.4}
						initialY={0}
						initialX={0}
						video={true}
						mediaOpacity={1}
						bgColor="black">
						<FadeInAnimation threshold={0.4} duration={1}>
							<Box p={8}>
								<FadeInAnimation
									threshold={0.4}
									delay={0.4}
									duration={0.7}
									initialX={0}
									initialY={-10}>
									<Text color="white" fontSize="4xl">
										Investor Relations.
									</Text>
								</FadeInAnimation>

								<FadeInAnimation
									threshold={0.4}
									delay={1}
									duration={0.7}
									initialX={0}
									initialY={10}>
									<Text color="brand.green" fontSize="md">
										Information at your fingertips.
									</Text>
								</FadeInAnimation>
								<RouterLink
									isExternal={false}
									link={"/investorrelations"}>
									<Button
										mt={4}
										variant="solid"
										bg="brand.green"
										color="brand.darkgray">
										News & Investor Relations
									</Button>
								</RouterLink>
							</Box>
						</FadeInAnimation>
					</ImgTextModule>

					<ImgTextModule
						minH="450px"
						w="100%"
						src={"header-master.mp4"}
						delay={0.9}
						duration={1}
						threshold={0.4}
						initialY={0}
						initialX={0}
						video={true}
						mediaOpacity={1}
						bgColor="black">
						<FadeInAnimation threshold={0.4} duration={1}>
							<Box p={8}>
								<FadeInAnimation
									threshold={0.4}
									delay={0.4}
									duration={0.7}
									initialX={0}
									initialY={-10}>
									<Text color="white" fontSize="4xl">
										Experts.
									</Text>
								</FadeInAnimation>

								<FadeInAnimation
									threshold={0.4}
									delay={1}
									duration={0.7}
									initialX={0}
									initialY={10}>
									<Text color="brand.green" fontSize="md">
										From seniority to innovation.
									</Text>
								</FadeInAnimation>

								<RouterLink isExternal={false} link={"/team"}>
									<Button
										mt={4}
										variant="solid"
										bg="brand.green"
										color="brand.darkgray">
										Meet the team
									</Button>
								</RouterLink>
							</Box>
						</FadeInAnimation>
					</ImgTextModule>
				</Container>
			</Box>
		</>
	);
}
